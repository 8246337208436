export const tableHead = [
  {
    title: "Rider Name",
    width: "18%",
  },
  {
    title: "Contact No.",
    width: "18%",
  },
  {
    title: "Email Id",
    width: "24%",
  },
  {
    title: "Emergency Contact",
    width: "18%",
  },
  {
    title: "Blood Group",
    width: "10%",
  },
  {
    title: "Ride Status",
    width: "12%",
  },
];
