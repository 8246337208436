import axios from "axios";
import { API_URL } from "./apiUrl";
import { storage as LocalStorage } from "services/config/storage";
import { logout } from "utility/helperFunctions";

const _authorizationHeaders = () => {
  console.log("LocalStorage.fetch.authToken()", LocalStorage.fetch.authToken());
  const AuthToken = LocalStorage.fetch.authToken()
    ? LocalStorage.fetch.authToken()
    : "";
  if (AuthToken) {
    return {
      Authorization: `Bearer ${AuthToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
  return {
    "Content-Type": "application/json",
  };
};

const getStatusCode = (err) => {
  let code = null;
  if (err && err.statusCode) {
    code = err.statusCode;
  } else if (err && err.response && err.response.status) {
    code = err.response.status;
  }
  return code;
};

const handleError = (err) => {
  const errStatusCode = getStatusCode(err);
  if (errStatusCode) {
    if (errStatusCode === 401) {
      // auto logout if unauthorized (token expired)
      logout();
    }
    if (errStatusCode === 404 || errStatusCode === 502) {
      // Add custom handling for 404 or 502 here if needed
    }
  } else if (err?.isAxiosError) {
    err.response = err.response || {};
    err.response.data = err.response.data || {};
    err.response.data.message = err.message;
    err.response.message = err.message;
  }
  throw err;
};

export const getRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers),
    });
    return res.data;
  } catch (err) {
    handleError(err);
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const putRequest = async (
  url,
  data = {},
  isFile = false,
  type = "application/json",
  headers = isFile
    ? { ..._authorizationHeaders(), "Content-Type": type }
    : _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const deleteRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const uploadRequest = async (url, header = _authorizationHeaders()) => {
  try {
    const res = await axios({
      url,
      method: "PUT",
      headers: Object.assign({}, header),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const filePostRequest = async (
  url,
  data,
  isFile,
  type,
  headers = isFile
    ? { ..._authorizationHeaders(), "Content-Type": type }
    : _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

// Export all API methods
export const Api = {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  uploadRequest,
  filePostRequest,
};
