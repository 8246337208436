import React, { useState } from "react";
import { Td, TableList, Status } from "static/coreStyles/table.styled";
import { Button, Avatar,} from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import { getInitials } from "utility/helperFunctions";
import { formatToDateString } from "utility/helperFunctions";
import { EyeOutlined } from '@ant-design/icons';

export const RideList= ({
  data: {
    id,
    type,
    association,
    group,
    headedTo,
    startFrom,
    name,
    dayType,
    startDateTime,
    endDateTime,
    totalDistance,
    riderCount,
    rideMembers,
    limitRiders,
    allowedRiderCount,
    rideCharge,
    rideStatus,
    createdAt,
    isPaid,
    isRidePublic,
    isWomenOnly,
  },
}) => {
  

  if (!id) return null;

  const handleClickOpen = () => {
    
    window.location.href = '/ride/' + id;
  };

  return (
    
      <TableList > 
        <Td width="14%">
          <div style={{ display: "flex" }}>
            <Avatar
              size={27}
              style={{ backgroundColor: Theme.blueColor, marginRight: 10 }}
            >
              {rideMembers && rideMembers.length > 0
                ? getInitials(rideMembers[0].firstName, rideMembers[0].lastName)
                : ""}
            </Avatar>
            <div>
              {rideMembers && rideMembers.length > 0 && (
                <span>
                  {String(
                    rideMembers[0].firstName + " " + rideMembers[0].lastName
                  ).trim()}
                </span>
              )}
              {group && <em>{group.name}</em>}
            </div>
          </div>
        </Td>

      <Td width="14%">{name && <span style={{ display: "flex", flexWrap: 'wrap', wordBreak: 'break-all' }}>{name}</span>}</Td>

        <Td width="10%">
          {createdAt && <span>{formatToDateString(createdAt)}</span>}
        </Td>

        <Td width="10%">
          {startDateTime && <span>{formatToDateString(startDateTime)}</span>}
        </Td>

        <Td width="14%">
          {startDateTime && (
            <span style={{ display: "flex"}}>
              <span style={{ marginRight: 6 }}>
                {startFrom && startFrom.displayName
                  ? String(startFrom.displayName).trim()
                  : ""}
              </span>
              <span>→</span>
              <span style={{ marginLeft: 6 }}>
                {headedTo && headedTo.displayName
                  ? String(headedTo.displayName).trim()
                  : ""}
              </span>
            </span>
          )}
        </Td>

        <Td width="6%" style={{textAlign:"center", verticalAlign:"middle"}}>{riderCount && <span>{riderCount}</span>}</Td>

        <Td width="6%" style={{textAlign:"center", verticalAlign:"middle"}}>
          <span>{allowedRiderCount ? allowedRiderCount : ""}</span>
        </Td>

        <Td width="6%">
          {isPaid && rideCharge ? (
            rideCharge.amount > 0 ? (
              <span>₹{rideCharge.amount}</span>
            ) : (
              <span>Split</span>
            )
          ) : (
            <span>Free</span>
          )}
        </Td>

        <Td width="8%">
          <Status status={rideStatus}>{rideStatus}</Status>
        </Td>

        <Td width="8%">
          <span>{isRidePublic ? "✅ Public" : "🔒 Private"}</span>
        </Td>

        <Td width="5%" style={{ verticalAlign: "middle", textAlign: "center" }}>
          <Button icon={<EyeOutlined/>} onClick={handleClickOpen} >
          </Button>
        </Td>
      </TableList>
    
  );
};
