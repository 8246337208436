import React from "react";
import { Avatar, Table, Tag } from "antd";
import { getInitials } from "utility/helperFunctions";
import { tableHead } from "../data";

export const RideListData = ({ riders }) => {
  if (!riders || riders.length === 0) {
    return <p>No riders available for this ride.</p>;
  }

  // Map `tableHead` to Ant Design Table columns
  const columns = tableHead.map((column) => {
    switch (column.title) {
      case "Rider Name":
        return {
          title: <span style={{ color: "#656574", fontSize: "16px" }}>{column.title}</span>,
          dataIndex: "profilePic",
          key: "profilePic",
          render: (text, record) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                size={40}
                src={record?.profilePic}
                style={{ backgroundColor: "#87d068", marginRight: 12 }}
              >
                {!record.profilePic && getInitials(record.firstName, record.lastName)}
              </Avatar>
              <div>
                <strong style={{ fontSize: "16px" }}>
                  {record?.firstName} {record?.lastName}
                </strong>
                <br />
                <span style={{ color: "#8c8c8c", fontSize: "14px" }}>@{record?.userName}</span>
              </div>
            </div>
          ),
        };
      case "Contact No.":
        return {
          title: <span style={{ color: "#656574", fontSize: "16px" }}>{column.title}</span>,
          dataIndex: "phoneNumber",
          key: "phoneNumber",
          render: (text) => <span style={{ fontSize: "14px" }}>{text || "N/A"}</span>,
        };
      case "Email Id":
        return {
          title: <span style={{ color: "#656574", fontSize: "16px" }}>{column.title}</span>,
          dataIndex: "email",
          key: "email",
          render: (text) => <span style={{ fontSize: "14px" }}>{text || "N/A"}</span>,
        };
      case "Emergency Contact":
        return {
          title: <span style={{ color: "#656574", fontSize: "16px" }}>{column.title}</span>,
          dataIndex: "emergencyContactNo",
          key: "emergencyContactNo",
          render: (text) => <span style={{ fontSize: "14px" }}>{text || "N/A"}</span>,
        };
      case "Blood Group":
        return {
          title: <span style={{ color: "#656574", fontSize: "16px" }}>{column.title}</span>,
          dataIndex: "bloodGroup",
          key: "bloodGroup",
          render: (text) => <span style={{ fontWeight: 500, fontSize: "14px"}}>{text || "N/A"}</span>,
        };
      case "Ride Status":
        return {
          title: <span style={{ color: "#656574", fontSize: "16px" }}>{column.title}</span>,
          dataIndex: "inRideMemberStatus",
          key: "inRideMemberStatus",
          render: (status) => {
            // Map backend status to display status
            const statusMapping = {
              JOINED: "Started",
              FINISHED: "Finished",
              CANCELLED: "Cancelled",
              DELETED: "Deleted",
            };

            const styles = {
              Started: {
                color: "#11B95C",
                backgroundColor: "#E7FAF0",
                borderRadius: "12px",
              },
              Finished: {
                color: "#6528D8",
                backgroundColor: "#F0E8FF",
                borderRadius: "12px",
              },
              Cancelled: {
                color: "red",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                borderRadius: "12px",
              },
              Deleted: {
                color: "red",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                borderRadius: "12px",
              },
              "Not Started": {
                color: "#FFAA0F",
                backgroundColor: "#FFF7EA",
                borderRadius: "12px",
              },
            };

            // Determine the display status
            const displayStatus = statusMapping[status] || "Not Started";

            return (
              <Tag style={{ ...styles[displayStatus], padding: "4px 12px", border: "none" }}>
                {displayStatus}
              </Tag>
            );
          },
        };

      default:
        return null;
    }
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Stack heading and table vertically
        padding: "24px",
      }}
    >
      {/* Heading */}
      <h2 style={{ fontSize: "20px", fontWeight: "600", color: "#000000", marginBottom: "12px" }}>
        Riders Details
      </h2>

      {/* Table Container */}
      <div
        style={{
          backgroundColor: "#f5f6fa", 
          borderRadius: "8px", 
          overflow: "hidden",  
          width: "1140px",
        }}
      >
        <Table
          dataSource={riders.map((rider, index) => ({ ...rider, key: rider.rideMemberId || index }))}
          columns={columns}
          pagination={false}
          bordered={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          style={{ border: "none" }}
        />
      </div>
      <style>
        {`
          .ant-table-thead > tr > th {
            background-color: #f5f6fa; 
            font-weight: 600;
            text-align: left;
            padding: 16px;
            border-bottom: none; 
            font-size: 16px;
            color: #595959;
          }

          .ant-tag {
            font-size: 12px;
            padding: 4px 8px;
          }
        `}
      </style>
    </div>
  );
};
