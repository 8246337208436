import React, { useEffect, useState } from "react";
import {
  Container,
  PageHeader,
  TitleHead,
  PageWrap,
  Button,
  FilterContainer,
  Filter,
  FilterTitle,
  FilterItem,
} from "static/coreStyles/global.styled";
import TableHead from "components/TableHead";
import { tableHead } from "./data";
import {
  TableWrap,
  TableHeadWrap,
  TableContentWrap,
} from "static/coreStyles/table.styled";
import { GroupList } from "./GroupList/GroupList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmptyState from "components/emptyState";
import Pagination from "components/pagination";
import { getGroupList, setFilters, updateFilters } from "actions/group";
import { useDebounce } from "utility/helperFunctions";
import { DatePicker, Modal, Slider, Switch } from "antd";
import { useSetState } from "utility/helperFunctions";
import { Theme } from "static/coreStyles/theme.styled";
import Search from "components/Search";
import moment from "moment";
import { filter, omit } from "lodash";
import { HeaderButtons } from "components/Header";

const { RangePicker } = DatePicker;
function Groups({
  groupList,
  fetchGroupList,
  updateGroupFilter,
  setGroupFilter,
  filters,
  pagination,
}) {
  const sortMap = filters.sort || {};
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (debouncedSearchTerm) {
      updateGroupFilter({ query: debouncedSearchTerm });
    } else {
      updateGroupFilter({ query: "" });
    }
  }, [debouncedSearchTerm]);

  const handleSort = (key, value) => {
    if (value === "") {
      const sortKeys = omit(filters.sort, key);
      updateGroupFilter({ sort: sortKeys });
    } else {
      updateGroupFilter({
        sort: {
          ...(filters.sort || {}),
          [key]: value,
        },
      });
    }
  };

  useEffect(() => {
    fetchGroupList({...filters, pageSize: 10 });
  }, [filters]);

  return (
    <PageWrap>
      <Container>
        <PageHeader>
          <TitleHead>
            <Search
              setSearchTerm={setSearchTerm}
              onFilterClick={() => setModalVisible(true)}
              filters={filters}
              setFilter={setGroupFilter}
            />
            <HeaderButtons />
          </TitleHead>
        </PageHeader>
        {groupList && groupList.length > 0 ? (
          <TableWrap>
            <TableHeadWrap className="group-table-head">
              {tableHead.map((item, index) => {
                const sortingKey =
                  item.sortKey &&
                  sortMap &&
                  sortMap.hasOwnProperty(item.sortKey)
                    ? sortMap[item.sortKey]
                    : null;
                return (
                  <TableHead
                    data={item}
                    key={index}
                    isSorted={!!item.sortKey}
                    handleSort={() => {
                      if (sortingKey === "desc") {
                        handleSort(item.sortKey, "asc");
                      } else if (sortingKey === "asc") {
                        handleSort(item.sortKey, "");
                      } else {
                        handleSort(item.sortKey, "desc");
                      }
                    }}
                    sort={sortingKey}
                  />
                );
              })}
            </TableHeadWrap>
            <TableContentWrap>
              {groupList.map((item, index) => (
                <GroupList data={item} key={index} />
              ))}
            </TableContentWrap>
          </TableWrap>
        ) : (
          <EmptyState />
        )}
        <Pagination
          page={filters.page}
          pageSize={10}
          pagination={pagination}
          updatePage={(newPage) => updateGroupFilter({ page: newPage })}
        />
        <FilterModal
          filters={filters}
          visible={modalVisible}
          setVisible={setModalVisible}
          setFilter={setGroupFilter}
          updateFilter={updateGroupFilter}
        />
      </Container>
    </PageWrap>
  );
}

function FilterModal({ visible, setVisible, setFilter, filters }) {
  const [state, setState] = useSetState({});

  useEffect(() => {
    // Reset modal state when filters are cleared
    if (!filters || Object.keys(filters).length === 0) {
      setState({
        dateRange: null,
        distanceRange: null,
        memberRange: null,
        rideCountRange: null,
        isVerified: null,
      });
    }
  }, [filters]);

  return (
    <Modal
      title="Apply Filter"
      visible={visible}
      onCancel={() => {
        setState({
          dateRange: null,
          distanceRange: null,
          memberRange: null,
          rideCountRange: null,
          isVerified: null,
        });
        setVisible(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setFilter(null); // Clear filters globally
            setState({
              dateRange: null,
              distanceRange: null,
              memberRange: null,
              rideCountRange: null,
              isVerified: null,
            });
            setVisible(false);
          }}
        >
          Reset
        </Button>,
        <Button
          key="submit"
          primary
          color={Theme.blueColor}
          onClick={() => {
            const {
              dateRange,
              distanceRange,
              memberRange,
              rideCountRange,
              isVerified,
            } = state;
            const filterData = {};
            if (dateRange) {
              if (dateRange[0]) {
                filterData["creationDateAfter"] = moment(dateRange[0])
                  .format("YYYY-MM-DD")
                  .toString();
              }
              if (dateRange[1]) {
                filterData["creationDateBefore"] = moment(dateRange[1])
                  .format("YYYY-MM-DD")
                  .toString();
              }
            }
            if (distanceRange) {
              filterData["distanceRange"] = `${distanceRange[0]}-${distanceRange[1]}`;
            }
            if (memberRange) {
              filterData["memberRange"] = `${memberRange[0]}-${memberRange[1]}`;
            }
            if (rideCountRange) {
              filterData["rideCountRange"] = `${rideCountRange[0]}-${rideCountRange[1]}`;
            }
            if (isVerified) {
              filterData["isVerified"] = isVerified;
            }
            setFilter(filterData);
            setVisible(false);
          }}
        >
          Apply
        </Button>,
      ]}
    >
      <FilterContainer>
        <Filter>
          <FilterTitle>Creation Date Range</FilterTitle>
          <FilterItem>
            <RangePicker
              allowEmpty={[true, true]}
              value={state.dateRange}
              onChange={(value) => setState({ dateRange: value })}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Distance Range</FilterTitle>
          <FilterItem>
            <Slider
              style={{ flex: 1 }}
              step={1}
              min={0}
              max={100}
              tooltipPlacement="bottomLeft"
              range
              value={state.distanceRange}
              onChange={(value) => setState({ distanceRange: value })}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Members Range</FilterTitle>
          <FilterItem>
            <Slider
              style={{ flex: 1 }}
              step={1}
              min={0}
              max={20}
              tooltipPlacement="bottomLeft"
              range
              value={state.memberRange}
              onChange={(value) => setState({ memberRange: value })}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Ride Count Range</FilterTitle>
          <FilterItem>
            <Slider
              style={{ flex: 1 }}
              step={1}
              min={0}
              max={20}
              tooltipPlacement="bottomLeft"
              range
              value={state.rideCountRange}
              onChange={(value) => setState({ rideCountRange: value })}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Verified Groups</FilterTitle>
          <FilterItem>
            <Switch
              checked={state.isVerified}
              onChange={(checked) => setState({ isVerified: checked })}
            />
          </FilterItem>
        </Filter>
      </FilterContainer>
    </Modal>
  );
}

function mapStateToProps(state) {
  const {
    Group: { groupList, filters, pagination },
  } = state;
  return { groupList, filters, pagination };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGroupList: bindActionCreators(getGroupList, dispatch),
    setGroupFilter: bindActionCreators(setFilters, dispatch),
    updateGroupFilter: bindActionCreators(updateFilters, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
