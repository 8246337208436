import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Space, Avatar, Typography } from 'antd';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getRideDetails } from 'actions/rides';
import { connect } from 'react-redux';
import { formatToDateString, getInitials } from 'utility/helperFunctions';
import { Theme } from 'static/coreStyles/theme.styled';
import { RideListData } from './RideListData/RideListData'; 
import TableHead from "components/TableHead";
import { tableHead } from "./data";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {
  TableWrap,
  TableHeadWrap,
  TableContentWrap,
} from "static/coreStyles/table.styled";

const { Title, Text } = Typography;

const RideDetailsPage = (props) => {
  const { rideId } = useParams();
  return <RideDetailsComponent rideId={rideId} {...props} />;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  }).replace(' ', "'");
};

const Header = ({ handleBackClick, rideDetails, handleDownload }) => (
  <div
    className="header"
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
      marginBottom: '16px',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        icon={<ArrowLeftOutlined style={{ color: 'black' }} />}
        onClick={handleBackClick}
        type="link"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      <Title level={4} style={{ marginLeft: '16px', margin: 0 }}>
        {rideDetails?.name ?? 'N/A'}
      </Title>
    </div>
    <Button
      icon={<DownloadOutlined />}
      onClick={handleDownload}
      style={{
        border: '1px solid #8e44ad',
        color: '#6528D8',
        borderRadius: '6px',
      }}
    >
      Download Ride Details
    </Button>
  </div>
);

const RideInformation = ({ rideDetails }) => (
  <Card
    style={{
      marginBottom: 24,
      borderRadius: '8px',
      width: '80%',
      backgroundColor: '#fff',
      border: '1px solid #eaeaea',
    }}
    bodyStyle={{ padding: 24 }}
  >
    <Row gutter={16}>
      <Col span={12}>
        <Space direction="vertical" size={10}>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              RIDE NAME
            </Text>
            <Text style={{ display: 'block', fontSize: '16px'}}>{rideDetails?.name}</Text>
          </div>
          <div style={{ marginTop: '26px' }}>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              FROM → TO
            </Text>
            <Text style={{ display: 'block', fontSize: '16px'}}>
              {rideDetails?.rideDetails[0]?.startPoint?.displayName ?? "N/A"} → {rideDetails?.rideDetails[0]?.endPoint?.displayName ?? "N/A"}
            </Text>
          </div>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              CREATION DATE
            </Text>
            <Text style={{ display: 'block', fontSize: '16px'}}>
              {formatToDateString(rideDetails?.createdAt) ?? 'N/A'}
            </Text>
          </div>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              PRIVACY
            </Text>
            <Text style={{ display: 'block' , fontSize: '16px'}}>
              {rideDetails?.isRidePublic != null ? (rideDetails?.isRidePublic ? 'Public' : 'Private') : 'N/A'}
            </Text>
          </div>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              PRICE
            </Text>
            <Text style={{ display: 'block', fontSize: '16px' }}>
            {rideDetails?.rideCharge 
              ? rideDetails?.rideCharge?.isSplit 
                ? 'Split' 
                : `Rs.${rideDetails?.rideCharge?.amount}` 
              : 'Free'}
          </Text>

          </div>
        </Space>
      </Col>

      <Col span={12}>
        <Space direction="vertical" size={10}>
          <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
            RIDE CREATOR
          </Text>
          <Space style={{ marginTop: '-4px' , fontSize: '16px'}}>
            <Avatar
              style={{ backgroundColor: Theme.blueColor, marginRight: 10 }}
              src={rideDetails?.rideMembers?.[0]?.profilePic}
            >
              {(!rideDetails?.rideMembers?.[0]?.profilePic && rideDetails?.rideMembers?.length > 0)
                ? getInitials(rideDetails?.rideMembers[0]?.firstName, rideDetails?.rideMembers[0]?.lastName)
                : ''}
            </Avatar>
            <div>
              <Text strong style={{ display: 'block' , fontSize: '16px'}}>
                {rideDetails?.rideMembers?.[0]?.firstName || 'N/A'}
              </Text>
              <Text type="secondary">
                @{rideDetails?.rideMembers?.[0]?.userName || 'N/A'}
              </Text>
            </div>
          </Space>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              RIDE CATEGORY
            </Text>
            <Text style={{ display: 'block', fontSize: '16px'}}>
              {rideDetails?.association ?? 'N/A'}
            </Text>
          </div>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              RIDE START DATE
            </Text>
            <Text style={{ display: 'block' , fontSize: '16px'}}>
              {formatToDateString(rideDetails?.startDateTime) ?? 'N/A'}
            </Text>
          </div>
          <div>
            <Text style={{ color: '#606060', fontSize: '14px', fontStyle: 'Roboto' }} type="secondary">
              RIDE TYPE
            </Text>
            <Text style={{ display: 'block', fontSize: '16px'}}>
              {rideDetails?.type ?? 'N/A'}
            </Text>
          </div>
        </Space>
      </Col>
    </Row>
  </Card>

  
);

const RideDetails = ({ rideId, getRideDetails, rideDetails }) => {
  const [riders, setRiders] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (rideId) {
      getRideDetails(rideId);
    }
  }, [rideId]);

  const handleBackClick = () => {
    history.push('/rides');
  };

  const handleDownload = async () => {
    const element = document.querySelector('.rider-details-section'); // Target only the rider details section
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
  
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
    let yPosition = 0;
  
    // Add pages as necessary
    while (yPosition < imgHeight) {
      pdf.addImage(imgData, 'PNG', 0, -yPosition, imgWidth, imgHeight);
      yPosition += pageHeight;
      if (yPosition < imgHeight) {
        pdf.addPage();
      }
    }
  
    pdf.save('riders-details.pdf');
  };
  

  return (
    <div
      className="ride-details-page"
      style={{ padding: '24px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Header handleBackClick={handleBackClick} rideDetails={rideDetails} handleDownload={handleDownload} />
      <RideInformation rideDetails={rideDetails} />
      
      <div className="rider-details-section">
        <RideListData riders={rideDetails?.rideMembers || []} />
      </div>

    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const rideId = ownProps ? ownProps.rideId : undefined;
  return {
    rideDetails: rideId && state.Ride ? state.Ride[rideId] : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRideDetails: bindActionCreators(getRideDetails, dispatch),
  };
}

const RideDetailsComponent = connect(mapStateToProps, mapDispatchToProps)(RideDetails);

export default RideDetailsPage;
