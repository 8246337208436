import { Api } from "../config/request";

export const getUsersList = (payload) => {
  return Api.postRequest(`admins/users`, payload);
};

export const getBlockedstatus = (id,payload) =>{
  return Api.putRequest(`admins/users/${id}`, payload);
}

const UserAPI = {
  getUsersList,
  getBlockedstatus
};

export default UserAPI;

