import React, { useEffect, useState } from "react";
import {
  Container,
  PageHeader,
  TitleHead,
  PageWrap,
  Button,
  FilterContainer,
  Filter,
  FilterTitle,
  FilterItem,
} from "static/coreStyles/global.styled";
import { tableHead } from "./data";
import {
  TableWrap,
  TableHeadWrap,
  TableContentWrap,
} from "static/coreStyles/table.styled";
import { UserList } from "./UserList/UserList";
import TableHead from "components/TableHead";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "actions/user";
import EmptyState from "components/emptyState";
import Pagination from "components/pagination";
import { useDebounce } from "utility/helperFunctions";
import { Modal } from "antd";
import { Theme } from "static/coreStyles/theme.styled";
import { DatePicker } from "antd";
import { Select } from "antd";
import { Slider } from "antd";
import moment from "moment";
import { useSetState } from "utility/helperFunctions";
import Search from "components/Search";
import { omit } from "lodash";
import { HeaderButtons } from "../../components/Header";
import { Switch } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

function Users({
  usersList,
  fetchUsersList,
  filters,
  updateUserFilter,
  setUserFilter,
  pagination,
}) {
  const sortMap = filters.sort || {};
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    if (debouncedSearchTerm) {
      updateUserFilter({
        query: debouncedSearchTerm,
      });
    } else {
      updateUserFilter({
        query: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
  const handleSort = (key, value) => {
    if (value === "") {
      const sortKeys = omit(filters.sort, key);
      updateUserFilter({
        sort: sortKeys,
      });
    } else {
      updateUserFilter({
        sort: {
          ...(filters.sort ? filters.sort : {}),
          [key]: value,
        },
      });
    }
  };

  useEffect(() => {
    fetchUsersList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <PageWrap>
      <Container>
        <PageHeader>
          <TitleHead>
            <Search
              setSearchTerm={setSearchTerm}
              onFilterClick={() => {
                setModalVisible(true);
              }}
              filters={filters}
              setFilter={setUserFilter}
            />
            <HeaderButtons />
          </TitleHead>
        </PageHeader>
        {usersList && usersList.length > 0 ? (
          <TableWrap>
            <TableHeadWrap className="user-table-head">
              {tableHead.map((item, index) => {
                const sortingKey =
                  item.sortKey &&
                  sortMap &&
                  sortMap.hasOwnProperty(item.sortKey)
                    ? sortMap[item.sortKey]
                    : null;
                return (
                  <TableHead
                    data={item}
                    key={index}
                    isSorted={!!item.sortKey}
                    handleSort={() => {
                      if (sortingKey === "desc") {
                        handleSort(item.sortKey, "asc");
                      } else if (sortingKey === "asc") {
                        handleSort(item.sortKey, "");
                      } else {
                        handleSort(item.sortKey, "desc");
                      }
                    }}
                    sort={sortingKey}
                  />
                );
              })}
            </TableHeadWrap>
            <TableContentWrap>
              {usersList.map((item, index) => {
                return <UserList data={item} key={index} />;
              })}
            </TableContentWrap>
          </TableWrap>
        ) : (
          <EmptyState />
        )}
        <Pagination
          page={filters.page}
          pagination={pagination}
          updatePage={(newPage) => {
            updateUserFilter({ page: newPage });
          }}
        />
        <FilterModal
          filters={filters}
          visible={modalVisible}
          setVisible={setModalVisible}
          setFilter={setUserFilter}
          updateFilter={updateUserFilter}
        />
      </Container>
    </PageWrap>
  );
}

function FilterModal({ visible, setVisible, setFilter }) {
  const [state, setState] = useSetState({
    blockedUsers: false,
  });
  
  return (
    <Modal
      title="Apply Filter"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setFilter(null);
            setState({
              dateRange: null,
              bloodGroup: [],
              ageRange: null,
              bikeCountRange: null,
              blockedUsers: false,
            });
            setVisible(false);
          }}
        >
          Reset
        </Button>,
        <Button
          key="submit"
          primary
          color={Theme.blueColor}
          onClick={() => {
            const { dateRange, bloodGroup, ageRange, bikeCountRange, blockedUsers } = state;
            const filterData = {};
            if (dateRange) {
              if (dateRange[0]) {
                filterData["joinDateAfter"] = moment(dateRange[0])
                  .format("YYYY-MM-DD")
                  .toString();
              }
              if (dateRange[1]) {
                filterData["joinDateBefore"] = moment(dateRange[1])
                  .format("YYYY-MM-DD")
                  .toString();
              }
            }
            if (bloodGroup) {
              filterData["bloodGroup"] = bloodGroup;
            }
            if (ageRange) {
              filterData["ageRange"] = `${ageRange[0]}-${ageRange[1]}`;
            }

            if (bikeCountRange) {
              filterData[
                "bikeCountRange"
              ] = `${bikeCountRange[0]}-${bikeCountRange[1]}`;
            }
            if (blockedUsers) {
              filterData["isActive"] = false; 
            }
            setFilter(filterData);
            setVisible(false);
          }}
        >
          Apply
        </Button>,
      ]}
    >
      <FilterContainer>
        <Filter>
          <FilterTitle>Date Range</FilterTitle>
          <FilterItem>
            <RangePicker
              allowEmpty={[true, true]}
              value={state.dateRange}
              onChange={(value) => {
                setState({
                  dateRange: value,
                });
              }}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Blood Group</FilterTitle>
          <FilterItem>
            <Select
              value={state.bloodGroup}
              mode="multiple"
              allowClear
              style={{ flex: 1 }}
              placeholder="Select Blood Group"
              onChange={(value) => {
                setState({
                  bloodGroup: value,
                });
              }}
            >
              {["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"].map(
                (item) => {
                  return (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  );
                }
              )}
            </Select>
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Age Range</FilterTitle>
          <FilterItem>
            <Slider
              style={{ flex: 1 }}
              step={1}
              min={0}
              max={100}
              tooltipPlacement="bottomLeft"
              range
              value={state.ageRange}
              onChange={(value) => {
                setState({
                  ageRange: value,
                });
              }}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Bike Count Range</FilterTitle>
          <FilterItem>
            <Slider
              style={{ flex: 1 }}
              step={1}
              min={0}
              max={20}
              tooltipPlacement="bottomLeft"
              range
              value={state.bikeCountRange}
              onChange={(value) => {
                setState({
                  bikeCountRange: value,
                });
              }}
            />
          </FilterItem>
        </Filter>
        <Filter>
          <FilterTitle>Blocked Users</FilterTitle>
          <FilterItem>
            <Switch
              checked={state.blockedUsers}
              onChange={(checked) =>
                setState({ blockedUsers: checked })
              }
            />
          </FilterItem>
        </Filter>
      </FilterContainer>
    </Modal>
  );
}

function mapStateToProps(state) {
  const {
    User: { usersList, filters, pagination },
  } = state;
  return {
    usersList,
    filters,
    pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsersList: bindActionCreators(userActions.getUserListAction, dispatch),
    setUserFilter: bindActionCreators(userActions.setUserFilter, dispatch),
    updateUserFilter: bindActionCreators(
      userActions.updateUserFilter,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
