import RideAPI from "services/ride"; // Import the RideAPI service
import swalState from "utility/swal";
import { message } from "antd";

function handleError(err) {
  console.error("Error in ride actions: ", err);
  message.error(err.message);
}

// Action to set ride details in the state
export function setRideDetails(payload) {
  return {
    type: "SET_RIDE_DETAILS",
    payload,
  };
}

// Async function to fetch ride details using the rideId
export const getRideDetails = (rideId) => async (dispatch) => {
  try {
    swalState.showLoader();
    let response = await RideAPI.getRideById(rideId);
    if (response) {
      swalState.hideLoader();
      dispatch(setRideDetails(response.data));
    }
  } catch (err) {
    swalState.hideLoader();
    handleError(err);
  }
};
