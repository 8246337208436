
export const tableHead = [
  {
    title: "User details",
    sortKey: 'name',
    width: '17%',
  },
  {
    title: "Number",
    width: '12%',
  },
  {
    title: "Join Date",
    sortKey: 'createdAt',
    width: '10%',
  },
  {
    title: "Age",
    sortKey: 'age',
    width: '6%',
  },
  {
    title: "Blood Group",
    width: '9%',
  },
  {
    title: "Groups",
    sortKey: "groups",
    width: '8%',
  },
  {
    title: "Completed Rides",
    sortKey: "rides",
    width: '8%',
  },
  {
    title: "Total Joined Rides",
    sortKey: "totalRides",
    width: '9%',
  },
  {
    title: "Country",
    sortKey: "alphaCode",
    width: '10%',
  },
  {
    title: "Bikes",
    width: '6%',
  },
  {
    title: "Action",
    sortKey: "",
    width: "5%",
    textAlign: "center",
  },
];
