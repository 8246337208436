import React, { useState } from "react";
import { Td, TableList } from "static/coreStyles/table.styled";
import countryData from "static/data/country.json";
import { calculateAge, formatToDateString, getInitials } from "utility/helperFunctions";
import { Theme } from "static/coreStyles/theme.styled";
import { Avatar, Button, Modal, Switch, Typography, message } from "antd";
import { SettingOutlined, ExclamationOutlined } from '@ant-design/icons';
import { updateGroupDetails } from "actions/user";
import { useDispatch } from 'react-redux';

export const UserList = ({
  data: {
    id,
    firstName,
    lastName,
    userName,
    dob,
    bloodGroup,
    groupsCount,
    bikesCount,
    ridesCount,
    totalRidesCount,
    alphaCode,
    phoneNumber,
    countryCode,
    profilePic,
    createdAt,
    isActive,
  },
}) => {
  const [open, setOpen] = useState(false);
  const [userActive, setUserActive] = useState(isActive);
  const [tempUserActive, setTempUserActive] = useState(userActive);
  const dispatch = useDispatch();
  const { Paragraph } = Typography;

  if (!id) return null;

  const handleClickOpen = () => {
    setTempUserActive(isActive); // Set tempUserActive to the current userActive state
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const updates = { isActive: tempUserActive };
    try {
      await dispatch(updateGroupDetails(id, updates));
      setUserActive(tempUserActive); // Update the main state if save is successful
      message.success("User status updated successfully!");
      handleClose();
    } catch (error) {
      message.error("Failed to update user status.");
    }
  };

  const rowStyle = {
    backgroundColor: isActive === false ? '#FFE6E6' : 'white',
    border: `1px solid ${isActive === false ? '#FFE6E6' : 'white'}`,
    borderRadius: '5px',
  };

  const alertIconStyle = {
    border: '1px solid ',
    position: 'absolute',
    left: '-28px',
    backgroundColor: '#E43700',
    color: 'white',
    borderRadius: '50%',
    fontSize: '16px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  };

  return (
    <TableList style={rowStyle} >
      <Td width="17%">
        <div style={{ display: "flex" }}>
          <div className="info" style={{ display: "flex", marginRight: 10 }}>
            {profilePic ? (
              <Avatar size={27} src={profilePic} alt="profilePic" />
            ) : (
              <Avatar style={{ backgroundColor: Theme.blueColor }} size={27}>
                {getInitials(firstName, lastName)}
              </Avatar>
            )}
          </div>
          <div>
            {firstName && <span>{String(firstName + " " + lastName).trim()}</span>}
            {userName && <em>{userName}</em>}
          </div>
        </div>
      </Td>
      <Td width="12%">
        {phoneNumber && (
          <span>
            {"+"}
            {countryCode} {phoneNumber}
          </span>
        )}
      </Td>
      <Td width="10%">
        {createdAt && <span>{formatToDateString(createdAt)}</span>}
      </Td>
      <Td width="6%">{dob && <span>{calculateAge(dob)}</span>}</Td>
      <Td width="9%">
        {bloodGroup && (
          <span style={{ display: "flex" }}>
            {bloodGroup}
            <span style={{ textTransform: "lowercase" }}></span>
          </span>
        )}
      </Td>
      <Td width="8%">{groupsCount && <span>{groupsCount}</span>}</Td>
      <Td width="8%">{ridesCount && <span>{ridesCount}</span>}</Td>
      <Td width="9%">{totalRidesCount && <span>{totalRidesCount}</span>}</Td>
      <Td width="10%">
        {countryData[countryCode] && (
          <span>
            {countryData[countryCode].flag} {countryData[countryCode].name}
          </span>
        )}
      </Td>
      <Td width="6%">{bikesCount && <span>{bikesCount}</span>}</Td>
      <Td width="5%" style={{ verticalAlign: "middle" }}>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          {isActive === false && (
            <ExclamationOutlined style={alertIconStyle} />
          )}
          <Button icon={<SettingOutlined />} onClick={handleClickOpen} />
        </div>
        <Modal
          title={<span style={{ fontSize: '24px', fontWeight: 'bold', color: '#919191' }}>Settings</span>}
          visible={open}
          onOk={handleSave}
          onCancel={handleClose}
          okText="Save"
          cancelText="Cancel"
          width={475}
          bodyStyle={{ padding: '20px' }}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={handleClose}
                style={{
                  width: '45%',
                  borderRadius: '25px',
                  borderColor: 'white',
                  color: '#6528D8',
                  fontWeight: 'bold'
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={handleSave}
                style={{
                  width: '45%',
                  borderRadius: '25px',
                  backgroundColor: '#6528D8',
                }}
              >
                Save
              </Button>
            </div>
          }
        >
          <div style={{ marginBottom: '5px' }}>
            <label
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '15px',
                font: 'Cabin',
              }}
            >
              <span>Block this user</span>
              <Switch
                checked={!tempUserActive}
                onChange={() => setTempUserActive(!tempUserActive)}
              />
            </label>
          </div>
        </Modal>
      </Td>
    </TableList>
  );
};
