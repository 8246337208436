import { Api } from "../config/request";

export const getRideList = (payload) => {
  return Api.postRequest(`admins/rides`, payload);
};

export const getRideById = (rideId) => {
  return Api.getRequest(`admins/rides/${rideId}`);
};

const RideAPI = {
  getRideList,
  getRideById
};

export default RideAPI;
